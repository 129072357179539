<template>
  <div class="jly">
    <div><img src="~assets/img/products/8.png" style="margin-top: 50px" alt=""></div>
    <div><img src="~assets/img/products/9.png" style="margin-top: 50px; margin-bottom: 50px" alt=""></div>
  </div>
</template>

<script>
  export default {
    name: "Jingluoyi"
  }
</script>

<style scoped>
.jly {
  text-align: center;
  width: 100%;
  min-width: 1220px;
}
</style>
